/* purgecss start ignore */
.card {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0.5rem 0.6875rem 0.5625rem;
  border-radius: theme('borderRadius.lg');
  letter-spacing: theme('letterSpacing.tight');

  @screen md {
    font-size: theme('fontSize.xl');
  }

  &::after {
    position: absolute;
    border: 1px solid;
    border-radius: theme('borderRadius.lg');
    content: '';
    inset: 0;
    pointer-events: none;
  }

  & audio,
  & .more-btn {
    width: calc(100% + 1.375rem);
    margin-block-end: -0.5625rem;
    margin-inline-start: -0.6875rem;
  }

  & .more-btn {
    @apply transition-colors duration-100 ease-linear;

    background-color: theme('colors.black');
    color: theme('colors.white');
    line-height: 1;
    padding-block: 0.5625rem;
    padding-inline-start: 0.6875rem;
  }
}

.card--primary {
  background-color: theme('colors.theme');
  color: theme('colors.white');

  &:after {
    border-color: theme('colors.theme');
    opacity: 0;
  }

  &:hover {
    background-color: theme('colors.white');
    color: theme('colors.theme');

    &::after {
      opacity: 1;
    }
  }
}

.card-podcast {
  &:hover {
    color: theme('colors.theme');

    & .more-btn {
      background-color: theme('colors.theme');
    }
  }
}
/* purgecss end ignore */
