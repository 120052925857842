/* purgecss start ignore */
.rte-p-sm {
  font-size: theme('fontSize.xs');

  @screen md {
    font-size: theme('fontSize.sm');
  }
}

.rte-p-xl {
  font-size: theme('fontSize.lg');

  @screen md {
    font-size: theme('fontSize.xl');
  }
}

.rte {
  font-size: theme('fontSize.base');

  @screen md {
    font-size: 1.3125rem;
  }

  & h2,
  & h3 {
    @apply rte-p-xl;
  }

  & ul {
    position: relative;
    list-style: none;
    padding-inline-start: 1rem;

    & li {
      &::before {
        position: absolute;
        content: '–';
        inset-inline-start: 0;
      }
    }
  }

  & a {
    text-decoration: underline;
  }

  & p {
    text-align: justify;
  }

  @nest .template-footer & {
    font-size: 1rem;

    & h2, & h3, & h4, & h5, & h6 {
      font-size: 1rem;

      & + p, & + ul, & + ol {
        margin-block-start: 0;
      }
    }
  }
}
/* purgecss end ignore */
