.text-justify-last {
  text-align-last: justify;
}

.text-shout.text-shout {
  font-size: theme('fontSize.xl');
  margin-block-start: 4.375rem;
  text-align: center;
  text-transform: uppercase;

  &:first-child {
    margin-block-start: 0;
  }

  @screen md {
    font-size: theme('fontSize.4xl');
    margin-block-start: 8.75rem;
  }

  & + * {
    margin-block-start: 4.375rem;

    @screen md {
      margin-block-start: 8.75rem;
    }
  }
}
