/* purgecss start ignore */
/* stylelint-disable */
.skd-widget.skd-widget * {
  color: theme('colors.black');
  font-family: 'DiatypePre', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;

  & a {
    &:hover {
      color: inherit !important;
    }
  }

  & .fa {
    font-family: FontAwesome;
  }
}

.skd-widget.skd-widget {
  & .skd-room {
    font-weight: 300;
  }

  & .skd-container-travel-period,
  & .skd-container-occupancy {
    padding-inline: 15px !important;
  }

  &
    .skd-check-availability-widget
    .skd-contentContainer
    .skd-search-box
    .skd-widget-form-inline
    .skd-container-btn-search
    .skd-btn-search {
    width: 100%;
  }

  & .skd-btn-to-offers,
  & .skd-share-row a,
  & .skd-occupancy-icons,
  &
    .skd-check-availability-widget
    .skd-contentContainer
    .skd-search-box
    .skd-add-room {
    &,
    & .skd-plus-sign {
      color: theme('colors.black') !important;
    }
  }

  & .skd-calendar-visible {
    padding: 0 !important;
    margin-block-end: 2rem;
  }

  & .skd-price-wrapper {
    color: theme('colors.black');
  }

  & .skd-container-policies .skd-policy h3 {
    color: theme('colors.black');
  }

  & .skd-widget-form-control {
    box-shadow: none;
  }

  & .skd-offer-list-widget .skd-precheckout .skd-offer-details::after {
    display: none;
  }

  &
    .skd-alternatives-offer
    .skd-alternative-offers-cal-container
    .skd-alternative-calendar
    .skd-date-available,
  &
    .skd-alternatives-offer
    .skd-alternative-offers-cal-container
    .skd-alternative-calendar
    .skd-only-arrival,
  &
    .skd-alternatives-offer
    .skd-alternative-offers-cal-container
    .skd-alternative-calendar
    .skd-only-departure,
  & .skd-alternatives-calendar-container .skd-legend .skd-item.skd-available,
  &
    .skd-alternatives-calendar-container
    .skd-calendar-legend
    .skd-item-container
    .skd-only-arrival,
  &
    .skd-alternatives-calendar-container
    .skd-calendar-legend
    .skd-item-container
    .skd-only-departure,
  &
    .skd-calendars-month
    td
    .skd-calendars-available:not(.skd-calendars-other-month),
  & .skd-calendars-month td .skd-calendars-only-arrival,
  & .skd-calendars-month td .skd-calendars-only-departure {
    background-color: theme('colors.theme');
    color: theme('colors.white');
  }

  &
    .skd-alternatives-calendar-container
    .skd-calendar-legend
    .skd-item-container
    .skd-date-available.skd-closed-for-arrival {
    background-color: theme('colors.theme-light');
  }

  &
    .skd-alternatives-offer
    .skd-alternative-offers-cal-container
    .skd-alternative-calendar
    .skd-end-date,
  &
    .skd-alternatives-offer
    .skd-alternative-offers-cal-container
    .skd-calendar-legend
    .skd-end-date,
  & .skd-alternatives-offer .skd-start-date,
  & .skd-alternative-offers-cal-container .skd-selected,
  & .skd-alternative-calendar .skd-end-date,
  & .skd-calendars-month td .skd-calendars-departure,
  & .skd-calendars-month td .skd-calendars-highlight-departure,
  & .skd-calendars-month td .skd-calendars-highlight,
  & .skd-calendars-month td .skd-calendars-highlight.skd-calendars-available,
  & .skd-calendars-month td .skd-calendars-selected,
  & .skd-calendars-month td .skd-calendars-selected.skd-calendars-available,
  & .skd-calendars-month td .skd-calendars-selected.skd-calendars-today,
  & .skd-calendars-month td .skd-calendars-selected.skd-calendars-weekend {
    background-color: theme('colors.black') !important;
  }

  &
    .skd-alternatives-offer
    .skd-alternative-offers-cal-container
    .skd-alternative-calendar
    .skd-start-date::after,
  &
    .skd-alternatives-offer
    .skd-alternative-offers-cal-container
    .skd-calendar-legend
    .skd-start-date::after,
  & .skd-calendars-month td .skd-calendars-arrival::after {
    border-color: transparent transparent transparent theme('colors.theme');
  }

  &
    .skd-alternatives-offer
    .skd-alternative-offers-cal-container
    .skd-alternative-calendar
    .skd-end-date::after,
  &
    .skd-alternatives-offer
    .skd-alternative-offers-cal-container
    .skd-calendar-legend
    .skd-end-date::after,
  & .skd-calendars-month td .skd-calendars-departure::after,
  & .skd-calendars-month td .skd-calendars-highlight-departure::after {
    border-color: theme('colors.theme') theme('colors.theme')
      theme('colors.theme') transparent !important;
  }

  & .skd-widget-alert-warning {
    background-color: theme('colors.theme-light');
    border: none;
  }
  & .skd-widget-btn-primary {
    background-color: theme('colors.theme') !important;
    border: none;
  }
  & .skd-btn-checkout {
    border-radius: theme('borderRadius.default');
    color: theme('colors.white') !important;

    & > span {
      color: theme('colors.white') !important;
    }
  }

  & .skd-calendars-unavailable span {
    color: theme('colors.black');
  }

  & .skd-calendars-available span,
  & .skd-calendars-only-arrival span,
  & .skd-calendars-only-departure span,
  & .skd-calendars-selected span {
    color: theme('colors.white');

    & div {
      color: theme('colors.white');
    }
  }
}

#kognitiv-payment img {
  width: auto;
}

/* purgecss end ignore */
