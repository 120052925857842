/* purgecss start ignore */
.hero {
  @apply flex;

  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  cursor: grab;
  padding-block: 1rem;
  user-select: none;

  @nest .slider-moving & {
    cursor: grabbing;
  }

  @screen md {
    @apply py-24;
  }
}

.hero-nav {
  @apply pt-div pb-div;

  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-block-start: theme('spacing.15px');

  @screen md {
    font-size: theme('fontSize.xl');
  }

  & ul {
    margin-inline: -0.25em;
  }

  & li {
    line-height: 1.1;
    padding-inline: 0.25em;
  }
}

.hero-bg {
  @apply no-flicker;

  position: absolute;
  z-index: -1;
  inset: 0;

  & figure {
    @apply no-flicker;

    width: 100%;
    height: 100%;
  }

  & video,
  & img {
    @apply no-flicker;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.hero-bg--split {
  & figure {
    height: 50%;

    @screen md {
      width: 50%;
      height: 100%;
    }
  }
}

.hero-bg--color {
  background-color: var(--theme-light-color);
}

.hero-bg--multiple {
  display: flex;
  align-items: center;
  padding-block: 8.75rem;

  @screen md {
    padding-block: 7rem 11rem;
  }

  & figure {
    width: 100%;
    max-width: 20vw;
    height: auto;

    @media (min-height: 680px) {
      max-width: 10.875rem;
    }
  }

  & img {
    object-fit: initial;
  }

  & .grid {
    overflow: hidden;
    width: 100%;
    max-width: theme('screens.lg');
    height: 100%;
    max-height: 31.25rem;
    margin-inline: auto;
    padding-inline: 3.75rem;

    @screen md {
      padding-inline: 1.875rem;
    }
  }

  & .w-1\/2 {
    display: flex;
    height: 50%;
    align-items: center;
    justify-content: center;

    @screen md {
      &:nth-child(1) {
        align-items: center;
        justify-content: center;
      }
      &:nth-child(2) {
        align-items: flex-start;
        justify-content: flex-end;
      }
      &:nth-child(3) {
        align-items: center;
        justify-content: flex-start;
      }
      &:nth-child(4) {
        align-items: flex-end;
        justify-content: center;
      }
    }
  }
}
/* purgecss end ignore */
