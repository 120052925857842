<template>
  <div ref="el" class="swiper-container h-full" id="page-swiper">
    <div class="swiper-wrapper" ref="wrapper">
      <slot
        :slidePrev="slidePrev"
        :slideNext="slideNext"
        :more="more"
        :scrollToSection="scrollToSection"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
  SetupContext,
} from '@vue/composition-api';
import { Swiper, History } from 'swiper/js/swiper.esm';
import {
  fixHeroLayout,
  fixIntroFontSize,
  slideIn,
  slideOut,
  isDescendant,
} from '../utils';

Swiper.use([History]);

export default defineComponent({
  setup(props, context: SetupContext) {
    const el: Ref<HTMLElement | null> = ref(null);
    const wrapper: Ref<HTMLElement | null> = ref(null);
    let swiper: Swiper | undefined;
    let reachedBeginning = false;
    let reachedEnd = false;
    let slides: any;
    const previousIndex = ref(0);
    const activeIndex = ref(0);
    const activeSlug = ref('');
    const activeSlide: Ref<HTMLElement | null> = ref(null);
    const activeHero: Ref<HTMLElement | null> = ref(null);
    const previousSlide: Ref<HTMLElement | null> = ref(null);
    const direction = ref('forward');

    const more = () => {
      if (activeSlide.value) {
        activeSlide.value.scrollBy({
          top: Math.floor(window.innerHeight),
          behavior: 'smooth',
        });
      }
    };

    const scrollToSection = (section: string) => {
      if (activeSlide.value) {
        const target = activeSlide.value.querySelector(
          `#${section}`,
        ) as HTMLElement;
        if (target) {
          const top = target.offsetTop;
          const {
            scrollMarginTop,
            scrollSnapMarginTop,
          } = window.getComputedStyle(target) as any;
          const offset = scrollSnapMarginTop || scrollMarginTop;

          activeSlide.value.scrollTo({
            top: top - parseInt(offset, 10),
            behavior: 'smooth',
          });
        }
      }
    };

    const animateSlide = (): void => {
      if (slides && activeSlide.value && previousSlide.value) {
        const slideWidth = activeSlide.value.clientWidth;
        const difference = activeIndex.value - previousIndex.value;
        const distance = difference * slideWidth;

        slideIn(activeSlide.value, direction.value);
        slideOut(previousSlide.value, direction.value, distance);
      }
    };

    const slidePrev = () => {
      if (swiper) {
        if (!reachedBeginning) {
          swiper.slidePrev(0);
        } else {
          swiper.slideTo(swiper.slides.length - 1, 0);
          reachedBeginning = false;
        }
        animateSlide();
      }
    };

    const slideNext = () => {
      if (swiper) {
        if (!reachedEnd) {
          swiper.slideNext(0);
        } else {
          swiper.slideTo(0, 0);
          reachedEnd = false;
        }
        animateSlide();
      }
    };

    const slideTo = (index: number) => {
      if (swiper && index !== activeIndex.value) {
        swiper.slideTo(index, 0);
        animateSlide();
      }
    };

    const updatePageTitle = (title: string): void => {
      document.title = title;
    };

    /**
     * @param {HTMLElement} element Slide or wrapper element.
     */
    const resetScrollPosition = (element: HTMLElement) => {
      setTimeout(() => {
        if (previousSlide.value) {
          previousSlide.value.scrollTo(0, 0);
        }
      }, 400);
    };

    const setActiveSlide = (index: number) => {
      activeIndex.value = index;
      activeSlide.value = slides[activeIndex.value];

      if (activeSlide.value) {
        activeHero.value = activeSlide.value.querySelector('.hero');

        if (activeSlide.value.dataset.history) {
          activeSlug.value = activeSlide.value.dataset.history;
        }
      }
    };

    const setPreviousSlide = (index: number) => {
      previousIndex.value = index;
      previousSlide.value = slides[previousIndex.value];
    };

    const fixViewportHeight = () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const updateSlides = (s: any) => {
      slides = s.slides;
      setActiveSlide(s.activeIndex);
      setPreviousSlide(s.previousIndex);
    };

    onMounted(() => {
      fixViewportHeight();

      if (el.value) {
        const disableInvisibleSlides = () => {
          Object.entries(slides)
            .filter(([key]) => parseInt(key, 10) !== activeIndex.value)
            .forEach(([key, value]: [string, unknown]) => {
              if (key !== 'length') {
                const slide = value as HTMLElement;
                slide.setAttribute('inert', 'inert');
              }
            });
        };

        swiper = new Swiper(el.value, {
          history: {
            key: '',
          },
          on: {
            init() {
              updateSlides(this);
              disableInvisibleSlides();
            },

            slideChange() {
              if (
                (this as any).previousIndex === 0 &&
                slides[0].dataset.history === ''
              ) {
                setTimeout(() => {
                  (this as any).removeSlide(0);
                }, 400);
              }
              updateSlides(this);
            },

            sliderMove() {
              if (el.value) {
                el.value.classList.add('slider-moving');
              }
            },

            touchStart(event?: any): void {
              // Only allow swiping on the hero element.
              if (activeSlide.value) {
                if (swiper) {
                  if (
                    activeHero.value &&
                    activeHero.value !== event.target &&
                    !isDescendant(activeHero.value, event.target)
                  ) {
                    swiper.allowTouchMove = false;
                  } else {
                    swiper.allowTouchMove = true;
                  }
                }
              }
            },

            touchEnd(): void {
              if (el.value) {
                el.value.classList.remove('slider-moving');
              }
              if (swiper) {
                swiper.allowTouchMove = false;
              }
              if (wrapper.value) {
                resetScrollPosition(wrapper.value);
              }
            },

            transitionStart() {
              direction.value =
                previousIndex.value < activeIndex.value
                  ? 'forward'
                  : 'backward';

              if (activeSlide.value) {
                if (activeSlide.value.dataset.title) {
                  updatePageTitle(activeSlide.value.dataset.title);
                }

                if (activeSlide.value.dataset.textColor === 'white') {
                  document.body.classList.add('hero-text-white');
                } else {
                  document.body.classList.remove('hero-text-white');
                }
              }
            },

            transitionEnd() {
              if (slides) {
                reachedBeginning = activeIndex.value === 0;
                reachedEnd = activeIndex.value === slides.length - 1;

                if (activeSlide.value) {
                  activeSlide.value.removeAttribute('inert');
                }
                disableInvisibleSlides();
              }

              if (previousSlide.value) {
                resetScrollPosition(previousSlide.value);
              }
            },
          },
        });

        fixHeroLayout();
        fixIntroFontSize();
        window.addEventListener('resize', fixHeroLayout);
        window.addEventListener('resize', fixIntroFontSize);
        window.addEventListener('resize', fixViewportHeight);
      }
    });

    const getIndexBySlug = (slug: string): number => {
      return Array.from(slides).findIndex(
        (slide: any) => slide.dataset.history === slug,
      );
    };

    context.root.$on('slideTo', (slug: string) => {
      const index = getIndexBySlug(slug);

      slideTo(index);
    });

    context.root.$on('slideNext', () => {
      slideNext();
    });

    watch(activeSlug, (value, oldValue) => {
      context.root.$emit('activeSlug', value);
      context.root.$emit('transitioningSlideSlugs', [value, oldValue]);

      setTimeout(() => {
        context.root.$emit('transitioningSlideSlugs', []);
      }, 300);
    });

    watch(direction, value => {
      context.root.$emit('direction', value);
    });

    return {
      el,
      wrapper,
      slidePrev,
      slideNext,
      more,
      previousIndex,
      activeIndex,
      scrollToSection,
    };
  },
});
</script>

<style src="swiper/css/swiper.css"></style>

<style lang="postcss" scop>
.swiper-slide {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
