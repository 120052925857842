/* purgecss start ignore */
.container {
  width: 100%;
  max-width: 69rem;
  margin-inline: auto;
  padding-inline: 0.625rem;

  @screen md {
    padding-inline: 2rem;
  }
  @screen xl {
    max-width: 94rem;
  }
}

.container-inner {
  width: 100%;
  max-width: 43rem;

  @screen xl {
    max-width: 65rem;
  }
}
/* purgecss end ignore */
