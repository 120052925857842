.filter-plain {
  position: relative;
  backface-visibility: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  transform: translate3d(0, 0, 0);

  & img {
    filter: grayscale();
    transition: filter 0.1s;
  }

  &::after {
    position: absolute;
    background-color: var(--theme-filter-color);
    content: '';
    inset: 0;
    mix-blend-mode: multiply;
    transition: opacity 0.1s;
  }

  @nest .teaser:hover & {
    & img {
      filter: none;
    }

    &::after {
      opacity: 0;
    }
  }
}

.filter-gradient {
  position: relative;
  backface-visibility: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  transform: translate3d(0, 0, 0);

  &::after {
    position: absolute;

    background: linear-gradient(
      45deg,
      #ff8500 0%,
      #ff751a 10%,
      #ff6534 20%,
      #ff554f 30%,
      #ff4569 40%,
      #fe3583 50%,
      #ea2a9c 60%,
      #d720b5 70%,
      #c315cd 80%,
      #af0ae6 90%,
      #9c00ff 100%
    );
    content: '';
    inset: 0;
    mix-blend-mode: multiply;
    transition: opacity 0.1s;
  }

  &:hover {
    &::after {
      opacity: 0;
    }
  }
}
