<template>
  <div class="flex items-center -mb-2" style="--focus-color: #fff">
    <div>
      <button
        class="playback-button px-3"
        v-on:click.prevent="playing = !playing"
        :title="playing == true ? 'Pause' : 'Play'"
        aria-controls="audiofile"
      >
        <span v-if="playing">Pause</span>
        <span v-else>Play</span>
      </button>
    </div>

    <input
      type="range"
      min="0"
      :max="durationSeconds"
      v-model="currentSeconds"
      @input="seek"
      class="flex-auto"
      tabindex="0"
      title="Audio seek bar"
      aria-valuetext="Audio seek bar"
      :aria-valuemax="durationSeconds"
      aria-valuemin="0"
      :aria-valuenow="Math.round(currentSeconds)"
      aria-controls="audiofile"
      role="slider"
    />

    <div class="px-3">{{ dirty ? currentTime : durationTime }}</div>
    <audio
      class="sr-only"
      :src="src"
      preload="auto"
      ref="audioElement"
      id="audiofile"
    ></audio>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  computed,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
  },

  setup() {
    const audioElement: Ref<HTMLAudioElement | null> = ref(null);
    const playing = ref(false);
    const loaded = ref(false);
    const dirty = ref(false);
    const durationSeconds = ref(0);
    const currentSeconds = ref(0);

    const load = () => {
      if (audioElement.value && audioElement.value.readyState >= 2) {
        loaded.value = true;
        durationSeconds.value = audioElement.value.duration;
        playing.value = !audioElement.value.paused;
      } else {
        throw new Error('Failed to load sound file.');
      }
    };

    const update = () => {
      if (audioElement.value) {
        currentSeconds.value = audioElement.value.currentTime;
      }
    };

    onMounted(() => {
      if (audioElement.value) {
        audioElement.value.addEventListener('timeupdate', update);
        audioElement.value.addEventListener('loadeddata', load);
        audioElement.value.addEventListener('pause', () => {
          playing.value = false;
        });
        audioElement.value.addEventListener('play', () => {
          playing.value = true;
        });
      }
    });

    const convertTimeHHMMSS = (val: number) => {
      const duration = new Date(val * 1000);

      return `${duration.toISOString().substr(11, 8)}`;
    };

    const currentTime = computed(() => {
      return convertTimeHHMMSS(currentSeconds.value);
    });

    const durationTime = computed(() => {
      return convertTimeHHMMSS(durationSeconds.value);
    });

    const seek = () => {
      if (audioElement.value) {
        audioElement.value.currentTime = currentSeconds.value;
      }
    };

    watch(playing, value => {
      if (audioElement.value) {
        if (value) {
          audioElement.value.play();
          dirty.value = true;
        } else {
          audioElement.value.pause();
        }
      }
    });

    return {
      audioElement,
      currentTime,
      playing,
      durationSeconds,
      currentSeconds,
      durationTime,
      seek,
      dirty,
    };
  },
});
</script>

<style lang="postcss">
.playback-button {
  width: 5.625rem;
  text-align: left;
}

input[type='range'] {
  height: 24px;
  margin: 10px 0;
  background: transparent;
  width: 100%;
  appearance: none;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    background: currentColor;
  }

  &::-webkit-slider-thumb {
    height: 1.25rem;
    width: 0.5rem;
    border: none;
    border-radius: theme('borderRadius.sm');
    background: currentColor;
    cursor: pointer;
    appearance: none;
    margin-top: -8.5px;
  }

  &::-webkit-slider-runnable-track {
    background: currentColor;
  }

  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    background: currentColor;
  }

  &::-moz-range-thumb {
    height: 1.25rem;
    border: none;
    width: 0.5rem;
    border-radius: theme('borderRadius.sm');
    background: currentColor;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-thumb {
    margin-top: 1px;
    border: none;
    height: 1.25rem;
    width: 0.5rem;
    border-radius: theme('borderRadius.sm');
    background: currentColor;
    cursor: pointer;
  }
}
</style>
