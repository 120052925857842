/* purgecss start ignore */
.navigation {
  position: fixed;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: theme('spacing.10px');
  font-size: theme('fontSize.base');
  inset-inline: 0;
  pointer-events: none;

  @screen md {
    padding: theme('spacing.5px');
  }

  & button,
  & a {
    pointer-events: auto;
  }
}

.navigation-item {
  @apply button-sm bg-white transition-colors duration-100 ease-linear;

  &.active {
    @apply bg-black text-white;

    @nest .hero-text-white & {
      @apply bg-theme;
    }
  }
}
/* purgecss end ignore */
