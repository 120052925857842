/* purgecss start ignore */
.toggle {
  position: relative;
  font-size: 1.125rem;
  line-height: 1;

  & > input[type='checkbox'] {
    position: absolute;
    width: 1.9rem;
    height: 1.1rem;
    opacity: 0;
  }

  &::after {
    position: absolute;
    margin: -0.75rem;
    content: '';
    cursor: pointer;
    inset: 0;
  }
}

.toggle-style {
  position: relative;
  display: inline-block;
  width: 1.6666666em;
  height: 1em;

  border-radius: 1em;
  cursor: pointer;
  inset-start: 0;
  transition: all 0.3s ease-in-out;

  &::before {
    position: absolute;
    display: block;
    width: 0.66666666666em;
    height: 0.66666666666em;
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    inset-start: 0.175em;
    transition: all 0.3s ease-in-out;

    @nest .toggle > input[type='checkbox']:checked + & {
      inset-inline-start: 50%;
    }

    @nest .toggle.text-theme > input[type='checkbox'] + & {
      background-color: theme('colors.theme');
    }

    @nest .toggle.toggle-color > input[type='checkbox']:checked + & {
      background-color: theme('colors.theme');
    }
  }

  &::after {
    position: absolute;
    border: 2px solid;
    border-radius: 1em;
    content: '';
    inset: 0;

    @nest .user-is-tabbing .toggle > input[type='checkbox']:focus + & {
      outline: 2px dashed black;
      outline-offset: 2px;
    }
  }
}
/* purgecss end ignore */
