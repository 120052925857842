<template>
  <div
    class="relative pointer-events-none cursor-none overflow-hidden"
    @mousemove="handleMousemove"
    @click="handleClick"
    ref="root"
  >
    <div class="image-swiper" ref="el" @scroll="handleScroll">
      <slot />
    </div>
    <div class="swiper-pagination" :style="paginationStyle">
      {{ paginationText }}
    </div>
    <div class="swiper-pagination-mobile">
      <svg width="16" height="16" class="fill-current">
        <use v-bind="{ 'xlink:href': '#pagination' }" />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Ref,
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';

export default defineComponent({
  setup() {
    const root: Ref<HTMLElement | null> = ref(null);
    const el: Ref<HTMLElement | null> = ref(null);
    const paginationStyle = ref({});
    const currentIndex = ref(0);
    const total = ref(0);
    let scrollTimeout: any;

    onMounted(() => {
      if (el.value) {
        total.value = el.value.childElementCount;
      }
    });

    const paginationText = computed(() => {
      return `${currentIndex.value + 1} / ${total.value}`;
    });

    const handleMousemove = (event: any) => {
      if (root.value) {
        const { top, left } = root.value.getBoundingClientRect();

        paginationStyle.value = {
          transform: `translate(calc(${event.clientX -
            left}px - 50%), calc(${event.clientY - top}px - 50%))`,
        };
      }
    };

    const slideWidth = (): number => {
      if (el.value) {
        return el.value.children[0].scrollWidth;
      }
      return 0;
    };

    const slideNext = (): void => {
      if (el.value) {
        if (currentIndex.value < total.value - 1) {
          currentIndex.value += 1;
          el.value.scrollBy({
            left: slideWidth(),
            behavior: 'smooth',
          });
        }
      }
    };

    const slidePrev = (): void => {
      if (el.value) {
        if (currentIndex.value > 0) {
          currentIndex.value -= 1;
          el.value.scrollBy({
            left: -slideWidth(),
            behavior: 'smooth',
          });
        }
      }
    };

    const handleClick = (event: any) => {
      if (root.value) {
        const { left } = root.value.getBoundingClientRect();
        const width = root.value.offsetWidth;

        if (event.clientX - left < width / 2) {
          slidePrev();
        } else {
          slideNext();
        }
      }
    };

    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        if (el.value) {
          currentIndex.value = Math.floor(el.value.scrollLeft / slideWidth());
        }
      }, 100);
    };

    return {
      root,
      el,
      paginationStyle,
      paginationText,
      handleMousemove,
      handleClick,
      handleScroll,
      slidePrev,
      slideNext,
    };
  },
});
</script>

<style lang="postcss">
.image-swiper {
  display: flex;
  margin-inline: -1.125rem;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  white-space: nowrap;
  pointer-events: all;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex: 0 0 100%;
    padding-inline: 1.125rem;
    scroll-snap-align: start;
    white-space: normal;
  }
}

.swiper-pagination {
  display: none;

  @screen lg {
    display: inline-block;
    pointer-events: none;
    top: 0;
    left: 0;
    font-size: theme('fontSize.xl');
    bottom: auto;
    width: auto;
    height: auto;
    padding: 0 1rem;
  }
}

.swiper-pagination-mobile {
  top: 0;
  right: 0;
  padding: 1rem;
  position: absolute;
  z-index: 10;
  pointer-events: none;

  @screen lg {
    display: none;
  }
}

.slider-nav-button {
  width: 50%;
  pointer-events: auto;

  @screen lg {
    cursor: none;
  }
}
</style>
