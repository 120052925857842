/* purgecss start ignore */

.swiper-slide-animate {
  overflow-y: hidden !important;
  transition: transform 300ms ease-out;
}

.swiper-slide {
  &.swiper-slide-active {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }
}
/* purgecss end ignore */
