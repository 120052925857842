.grid {
  display: flex;
  flex-wrap: wrap;
  margin: -0.1875rem;

  @screen md {
    margin: -0.5rem;
  }

  & > * {
    padding: 0.1875rem;

    @screen md {
      padding: 0.5rem;
    }
  }
}

@responsive {
  .gap-y-3 {
    margin-block: -0.75rem;
  }

  .gap-y-3 > * {
    padding-block: 0.75rem;
  }

  .gap-y-4 {
    margin-block: -1rem;
  }

  .gap-y-4 > * {
    padding-block: 1rem;
  }
}

.grid-tiles {
  display: flex;
  flex-wrap: wrap;
  margin: -0.1875rem;

  @screen md {
    margin: -0.5rem;
  }

  & > * {
    width: 100%;
    padding: 0.1875rem;

    @screen md {
      width: 50%;
      padding: 0.5rem;
    }
  }

  @supports (display: grid) {
    display: grid;
    margin: 0;
    gap: 0.375rem;
    grid-auto-rows: 1fr;

    & > * {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    @screen md {
      gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }
}
