a {
  @apply transition-colors duration-100 ease-linear;

  /* stylelint-disable-next-line */
  &:not(.card, .bg-theme, .bg-black, .navigation-item, .filter-gradient, .button, .button-sm) {
    &:hover {
      color: theme('colors.theme');
    }
  }
}
