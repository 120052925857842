:root {
  --green: theme('colors.green');
  --green-light: theme('colors.green-light');
  --green-filter: theme('colors.green-filter');
  --blue: theme('colors.blue');
  --blue-light: theme('colors.blue-light');
  --blue-filter: theme('colors.blue-filter');

  --theme-color: var(--green);
  --theme-light-color: var(--green-light);
  --theme-filter-color: var(--green-filter);
  --alternative-theme-color: var(--blue);
  --focus-color: var(--theme-color);
}

html {
  height: 100%;
  /* stylelint-disable */
  font-family: 'DiatypePre', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* stylelint-enable */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  text-rendering: geometricPrecision;
}
