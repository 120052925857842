/* purgecss start ignore */
.button {
  @apply inline-block px-2 rounded whitespace-no-wrap leading-none;

  padding: 0.3125rem 0.5rem 0.375rem;
  border-radius: theme('borderRadius.default');
  text-transform: none;

  @screen md {
    position: relative;
    padding: 0.5rem 0.6875rem 0.5625rem;
    font-size: 1.5625rem;

    &:not(.bg-theme) {
      &::after {
        position: absolute;
        border: 1px solid;
        border-radius: theme('borderRadius.default');
        content: '';
        inset: 0;
        opacity: 0;
        transition: opacity 40ms linear;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}

.button-sm {
  @apply inline-block rounded-sm whitespace-no-wrap leading-none;

  position: relative;
  padding: 0.125rem 0.3125rem;
  font-size: 1rem;
  letter-spacing: theme('letterSpacing.tight');
  text-transform: none;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    margin-block: -0.75rem;

    @screen lg {
      content: none;
    }
  }
}

.button-phone {
  position: relative;
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  align-items: center;
  justify-content: center;
  background-color: theme('colors.theme');
  border-radius: 9999px;

  &::after {
    position: absolute;
    margin: -0.75rem;
    content: '';
    inset: 0;

    @screen lg {
      content: none;
    }
  }
}

.button-toggle {
  @apply flex items-center w-full text-left outline-inset pt-div pb-div;

  font-size: theme('fontSize.lg');
  line-height: 1.1;

  @screen md {
    font-size: theme('fontSize.xl');
  }

  & > .button-icon {
    transition: transform 0.2s ease-out;
  }
  &.expanded {
    & > .button-icon {
      transform: scaleY(-1);
    }
  }
}
/* purgecss end ignore */
