/**
 * main.css
 *
 * The entry point for the css.
 *
 */

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */

@import 'tailwindcss/base';

/**
 * This injects any component classes registered by plugins.
 *
 */

@import 'tailwindcss/components';

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */

@import './elements/html';
@import './elements/body';
@import './elements/figure';
@import './elements/img';
@import './elements/p';
@import './elements/a';
@import './elements/hr';
@import './elements/abbr';
@import './generic/scroll-targets';
@import './components/container';
@import './components/button';
@import './components/navigation';
@import './components/card';
@import './components/podcast';
@import './components/slider';
@import './components/hero';
@import './components/rte';
@import './components/toggle';
@import './components/seekda';

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */
@import 'tailwindcss/utilities';

@import './utilities/text';
@import './utilities/teaser';
@import './utilities/filter';
@import './utilities/grid';
@import './utilities/outline';
@import './utilities/padding';
@import './utilities/arrow';
@import './utilities/no-flicker';
