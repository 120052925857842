<template>
  <div>
    <div class="summary" ref="body">
      <slot />
    </div>
    <div class="text-center mt-11" v-if="!visible">
      <button class="button-sm bg-theme text-white" @click="handleClick">
        {{ readMoreLabel }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, SetupContext } from '@vue/composition-api';
import { expand } from '../utils';

export default defineComponent({
  props: {
    readMoreLabel: {
      type: String,
      default: 'Read more',
    },
  },

  setup(props, context: SetupContext) {
    const body: Ref<HTMLElement | null> = ref(null);
    const visible = ref(false);

    const handleClick = () => {
      if (body.value) {
        expand(body.value);
        visible.value = true;
      }
    };

    return { body, visible, handleClick };
  },
});
</script>

<style lang="postcss">
.summary {
  overflow-y: hidden;
  max-height: 15em;
  transition: max-height 0.2s ease-out;

  @screen md {
    max-height: 11em;
  }
}
</style>
