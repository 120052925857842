.pt-div {
  padding-block-start: 0.4375rem;

  @screen md {
    padding-block-start: 0.875rem;
  }
}

.pb-div {
  padding-block-end: 0.5625rem;

  @screen md {
    padding-block-end: 1rem;
  }
}
