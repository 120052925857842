.teaser {
  position: relative;

  & a {
    &::after {
      position: absolute;
      content: '';
      inset: 0;
    }
  }
}
